import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/** MUI */
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Stack
} from "@mui/material";

/** Componentes */
import ResponsiveAppBar from "../components/navbar.component";

const MenuReporteRRHH = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState('');

    useEffect(()=>{
        const usuario = JSON.parse(localStorage.getItem("usuario"));
        setUser(usuario);
        if(usuario.perfil !== "administrador") {
            navigate("/");
        }
    }, []);

    return (
        <div>
            <ResponsiveAppBar id_empresa={user.id_empresa} />
            <Container sx={{
                justifyContent: "center"
            }}>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex"
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Card sx={{
                                boxShadow: 2,
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                display: "flex"
                            }}>
                                <CardContent>
                                    <Stack spacing={2} direction="column">
                                        <img src="img/icon_reporte.png" alt="Reporte Personal" width="130" height="150" />
                                        <Button variant="contained" sx={{backgroundColor: 'black'}} onClick={()=>navigate("/reportepersonal/''")}>PERSONAL</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card sx={{
                                boxShadow: 2,
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                display: "flex"
                            }}>
                                <CardContent>
                                    <Stack spacing={2} direction="column">
                                        <img src="img/icon_reporte.png" alt="Reporte Personal" width="130" height="150" />
                                        <Button variant="contained" sx={{backgroundColor: 'black'}} onClick={()=>navigate("/reportepersonal/b1f3f7f18d34c643134c")}>PERSONAL SPOT</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default MenuReporteRRHH;