import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from 'react-router-dom';

/** FireBase */
import { collection, getDocs, query, where } from 'firebase/firestore';
import FireBaseApp from "../firebase/firebase";

//const pages = ['Inicio', 'Datos Contrato'];
const settings = ['Perfil', 'Cuenta', 'Dashboard', 'Salir'];

function ResponsiveAppBar(props) {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { id_contrato, id_empresa } = props;
    const [user, loading_user, error] = useAuthState(FireBaseApp.auth);
    const [usuario, setUsuario] = React.useState('');
    const [pages, setPages] = React.useState([]);

    //const settings = [{nombre: 'Perfil', link: '#'}, {nombre: 'Cuenta', link: '#'}, {nombre: 'Dashboard', link: '#'}, {nombre:'Salir', link: FireBaseApp.logout}];

    React.useEffect(() => {
        if (loading_user) {
            return;
        }
        if (!user) {
            navigate("/");
        } else {
            retreiveUser(user.uid);
        }
    }, [user, loading_user]);

    const pges = [{ nombre: 'Menu', link: '/welcome' },{ nombre: 'Inicio', link: '/inicio/' + id_contrato + '/inicio' },  { nombre: 'Datos Contrato', link: '#' }, { nombre: 'Datos Personal', link: '/personal/' + id_contrato },
    { nombre: 'Procedimientos', link: '/procedimientos' + '/' + id_contrato }, { nombre: 'Asesorias', link: '#' }, { nombre: 'Reportes', link: '/reporterrhh' }, { nombre: 'M. Documentos', link: '/maestrodocumentos/' + id_contrato },
    { nombre: 'Brechas', link: '/brechas/' + id_contrato }];

    async function retreivePages(perfil) {
        var pagess = [];
        if (perfil === "analista_rrhh") {
            pagess = [{ nombre: 'Menu', link: '/welcome' }, { nombre: 'Datos Personal', link: '/personal/' + id_contrato }];
            setPages(pagess);
        } else if (perfil === "analista_apr") {
            pagess = [{ nombre: 'Menu', link: '/welcome' },{ nombre: 'Inicio', link: '/inicio/' + id_contrato + '/inicio' },  { nombre: 'Datos Contrato', link: '#' },
            { nombre: 'Procedimientos', link: '/procedimientos' + '/' + id_contrato }, { nombre: 'Asesorias', link: '#' }, { nombre: 'Reportes', link: '#' },
            { nombre: 'Brechas', link: '/brechas/' + id_contrato }];
            setPages(pagess);
        } else if (perfil === "gerencia") {
            pagess = [{ nombre: 'Menu', link: '/welcome' },{ nombre: 'Datos Personal', link: '/personal/' + id_contrato }];
            setPages(pagess);
        } else {
            setPages(pges);
        }
    }

    const menuUsuario = (nombre) => {
        if (nombre === "Salir") {
            localStorage.clear();
            FireBaseApp.logout();
        }
    };

    async function retreiveUser(uid) {
        const queryUsers = await query(collection(FireBaseApp.db, "users"), where("uid", "==", uid));
        const snapUsers = await getDocs(queryUsers);
        setUsuario({ id: snapUsers.docs[0].id, ...snapUsers.docs[0].data() });
        localStorage.setItem("usuario", JSON.stringify({ id: snapUsers.docs[0].id, ...snapUsers.docs[0].data() }));
        retreivePages(snapUsers.docs[0].data().perfil);
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'black' }}>
            <Container maxWidth="xl">
            {console.log("ID EMPRESA", id_empresa)}
                <Toolbar disableGutters>
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                    <img src={id_empresa == 'A0SZEZnZubnbSBlWlV4w' ? '/img/logo_schwager_blanco.png' : '/img/icon1024.png'} alt='Meta Prime' style={{
                        display: { xs: 'none', md: 'flex' },
                        mr: 1,
                        width: 90,
                        height: 35
                    }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >

                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.nombre} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center" component={Link} onClick={page.link}>{page.nombre}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        META PRIME
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.nombre}
                                onClick={() => navigate(page.link)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.nombre}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography component={Link} textAlign="center" onClick={() => menuUsuario(setting)}>{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;