import { useFormik } from 'formik';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, validate } from 'rut.js';
import * as Yup from 'yup';

/** MUI */
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

/** FIrebase */
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

import moment from 'moment';
import ResponsiveAppBar from '../components/navbar.component';

const schemaValido = Yup.object().shape({
    //apellido_materno: Yup.string().required("Dato requerido!"),
    apellido_paterno: Yup.string().required("Dato requerido!"),
    cargo: Yup.string().required("Dato requerido!"),
    comuna_residencia: Yup.string().required("Dato requerido!"),
    direccion_residencia: Yup.string().required("Dato requerido!"),
    //discapacidad: Yup.string().required("Dato requerido!"),
    //edad: Yup.number().required("Dato requerido!"),
    //etnia: Yup.string().required("Dato requerido!"),
    fecha_nacimiento: Yup.date().required("Fecha requerida!"),
    id_contrato: Yup.string().required("Dato reqierido!"),
    //inicio_contrato: Yup.date().required("Dato requerido!"),
    //jornada: Yup.string().required("Dato requerido!"),
    mail: Yup.string().required("Dato requerido!"),
    //motivo_desvinculacion: Yup.string().required("Dato requerido!"),
    nombre: Yup.string().required("Dato requerido!"),
    rut: Yup.string().required("Dato requerido!"),
    //sexo: Yup.string().required("Dato requerido!"),
    //tipo_contrato: Yup.string().required("Dato requerido!"),
});

function _calculateAge(birthday) { // birthday is a date
    console.log("Bir", birthday);
    var cumple = moment(birthday).toDate();
    console.log("Cumple", cumple);
    var ageDifMs = Date.now() - cumple.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const AddPersona = () => {
    const { id_contrato, id_persona } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [contratos, setContratos] = useState([]);
    const [usuario, setUsuario] = useState('');

    useEffect(() => {
        const usuar = JSON.parse(localStorage.getItem("usuario"));
        setUsuario(usuar);
        retreiveContratos(usuar.id_empresa);
        if (id_persona !== '0') {
            //Editamos al trabajador
            retreiveTrabajador(id_persona);
            //retreiveContratos();
            //setUsuario(JSON.parse(localStorage.getItem("usuario")));
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            id: '',
            apellido_materno: '',
            apellido_paterno: '',
            cargo: '',
            comuna_residencia: '',
            consideraciones_importantes: '',
            direccion_calama: '',
            direccion_residencia: '',
            discapacidad: '',
            edad: '',
            etnia: '',
            fecha_nacimiento: '',
            fono_contacto_emergencia: '',
            id_contrato: id_contrato,//id_contrato
            inicio_contrato: '',
            jornada: '',
            mail: '',
            motivo_desvinculacion: '',
            nombre: '',
            nombre_contacto_emeregencia: '',
            presenta_demanda: '',
            rut: '',
            sexo: '',
            sindicato: '',
            tipo_contrato: '',
            telefono_personal: '',
            id_empresa: JSON.parse(localStorage.getItem("usuario")).id_empresa,
            salud: '',
            afp: '',
            uf_salud: '',
            banco: '',
            tipo_cuenta_bancaria: '',
            numero_cuenta: '',
            nivel_educacional: '',
            titulo: '',
            talla_ropa: '',
            talla_calzado: '',
            promedio_nota_eval_desempeno: '',
            enfermedad_preexistente: '',
            antiguedad_empresa: '',
            vigente: '',
        },
        validationSchema: schemaValido,
        onSubmit: async () => {
            console.log(formik.values);
            if (formik.values.id) {
                console.log("Edición", formik.values);
                const perRef = await doc(FireBaseApp.db, "personas", formik.values.id);
                updateDoc(perRef, formik.values);
                enqueueSnackbar('Trabajador actualizado correctamente!', { variant: "success" });
            } else {
                console.log("INGRESO", formik.values);
                const perData = await addDoc(collection(FireBaseApp.db, "personas"), formik.values);
                console.log(perData.id);
                updateDoc(perData, {
                    id: perData.id
                });
                enqueueSnackbar('Trabajador ingresado correctamente!', { variant: "success" });
            }
        },
    });

    async function retreiveTrabajador(id_trab) {
        const trabRef = await doc(FireBaseApp.db, "personas", id_trab);
        const dataTrab = await getDoc(trabRef);
        const data = { id: dataTrab.id, ...dataTrab.data() }
        var isString = typeof dataTrab.data().inicio_contrato === 'string';
        console.log(isString);
        if (dataTrab.data().inicio_contrato != undefined & !isString) {
            console.log(typeof (dataTrab.data().inicio_contrato));
            data['inicio_contrato'] = dataTrab.data().inicio_contrato.toDate();
        }
        if (dataTrab.data().fecha_nacimiento != undefined) {
            data['fecha_nacimiento'] = dataTrab.data().fecha_nacimiento.toDate();
        }
        formik.setValues(data);
        if (data.fecha_nacimiento !== "") {
            console.log("Fecha N", data.fecha_nacimiento);
            var _edad = _calculateAge(data.fecha_nacimiento);
            formik.setFieldValue('edad', _edad, true);
        }
        if(data.inicio_contrato !== "") {
            var _antiguedad = _calculateAge(data.inicio_contrato);
            formik.setFieldValue('antiguedad_empresa',_antiguedad,true);
        }
    };

    const formatearRut = async (rut) => {
        if (validate(rut)) {
            console.log("Id EMP onblur", usuario.id_empresa)
            const queryPer = await query(collection(FireBaseApp.db, "personas"), where("rut", "==", format(rut)), where("id_empresa", "==", usuario.id_empresa));
            const dataPer = await getDocs(queryPer);
            if (!dataPer.empty) {
                if (dataPer.docs[0].exists) {
                    formik.setFieldValue('rut', "");
                    alert("Rut ya existente !!");
                    return;
                }
            } else {
                formik.setFieldValue('rut', format(rut));
            }
        } else {
            alert("Rut invalido !!");
            return;
        }
    };

    async function retreiveContratos(id_empresa) {
        var temp = [];
        const queryCont = query(collection(FireBaseApp.db, "contrato"), where("id_empresa", "==", id_empresa));
        const dataCon = await getDocs(queryCont);
        if (!dataCon.empty) {
            dataCon.forEach((dcon) => {
                temp.push({ id: dcon.id, ...dcon.data() });
                setContratos([...temp]);
                console.log("Temp Contratos", temp);
            });
        }
    };

    return (
        <div>
            <ResponsiveAppBar id_contrato={id_contrato} id_empresa={usuario.id_empresa} />
            <Container>
                <Box sx={{
                    margin: 3,
                    justifyContent: "start",
                    alignItems: "start",
                    display: "flex"
                }}>
                    <Button variant="contained" color="secondary" onClick={() => navigate('/personal/' + id_contrato)}>Volver</Button>
                </Box>
                <Box sx={{
                    margin: 3
                }}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Rut"
                                name="rut"
                                fullWidth
                                error={Boolean(formik.touched.rut && formik.errors.rut)}
                                onBlur={(e) => formatearRut(e.target.value)}
                                onChange={formik.handleChange}
                                value={formik.values.rut}
                                helperText={formik.touched.rut && formik.errors.rut}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Nombres"
                                name="nombre"
                                fullWidth
                                error={Boolean(formik.touched.nombre && formik.errors.nombre)}
                                onChange={formik.handleChange}
                                value={formik.values.nombre}
                                helperText={formik.touched.nombre && formik.errors.nombre}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Apellido Paterno"
                                name="apellido_paterno"
                                fullWidth
                                error={Boolean(formik.touched.apellido_paterno && formik.errors.apellido_paterno)}
                                onChange={formik.handleChange}
                                value={formik.values.apellido_paterno}
                                helperText={formik.touched.apellido_paterno && formik.errors.apellido_paterno}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Apellido Materno"
                                name="apellido_materno"
                                fullWidth
                                error={Boolean(formik.touched.apellido_materno && formik.errors.apellido_materno)}
                                onChange={formik.handleChange}
                                value={formik.values.apellido_materno}
                                helperText={formik.touched.apellido_materno && formik.errors.apellido_materno}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Cargo"
                                name="cargo"
                                fullWidth
                                error={Boolean(formik.touched.cargo && formik.errors.cargo)}
                                onChange={formik.handleChange}
                                value={formik.values.cargo}
                                helperText={formik.touched.cargo && formik.errors.cargo}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Fecha de Nacimiento"
                                    slotProps={{ textField: { fullWidth: true } }}
                                    format='dd-MM-yyyy'
                                    //value={document.fecha_nacimiento ? document.fecha_nacimiento : ""}
                                    value={formik.values.fecha_nacimiento ? formik.values.fecha_nacimiento : ""}
                                    onChange={(newValue) => formik.setFieldValue('fecha_nacimiento', newValue, true)}
                                    renderInput={(params) => <TextField {...params} fullWidth
                                        error={Boolean(formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento)}
                                        helperText={formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento}
                                    />}
                                />
                            </LocalizationProvider>
                            <Typography variant="inherit" color="textSecondary">
                                {formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento}
                            </Typography>
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Edad"
                                name="edad"
                                fullWidth
                                error={Boolean(formik.touched.edad && formik.errors.edad)}
                                onChange={formik.handleChange}
                                value={formik.values.edad}
                                helperText={formik.touched.edad && formik.errors.edad}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Fono Personal"
                                name="telefono_personal"
                                fullWidth
                                error={Boolean(formik.touched.telefono_personal && formik.errors.telefono_personal)}
                                onChange={formik.handleChange}
                                value={formik.values.telefono_personal}
                                helperText={formik.touched.telefono_personal && formik.errors.telefono_personal}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Comuna de Residencia"
                                name="comuna_residencia"
                                fullWidth
                                error={Boolean(formik.touched.comuna_residencia && formik.errors.comuna_residencia)}
                                onChange={formik.handleChange}
                                value={formik.values.comuna_residencia}
                                helperText={formik.touched.comuna_residencia && formik.errors.comuna_residencia}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Dirección de Calama"
                                name="direccion_calama"
                                fullWidth
                                error={Boolean(formik.touched.direccion_calama && formik.errors.direccion_calama)}
                                onChange={formik.handleChange}
                                value={formik.values.direccion_calama}
                                helperText={formik.touched.direccion_calama && formik.errors.direccion_calama}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Dirección de Residencia"
                                name="direccion_residencia"
                                fullWidth
                                error={Boolean(formik.touched.direccion_residencia && formik.errors.direccion_residencia)}
                                onChange={formik.handleChange}
                                value={formik.values.direccion_residencia}
                                helperText={formik.touched.direccion_residencia && formik.errors.direccion_residencia}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Discapcidad"
                                name="discapacidad"
                                fullWidth
                                select
                                error={Boolean(formik.touched.discapacidad && formik.errors.discapacidad)}
                                onChange={formik.handleChange}
                                value={formik.values.discapacidad}
                                helperText={formik.touched.discapacidad && formik.errors.discapacidad}
                            >
                                <MenuItem value="SI">SI</MenuItem>
                                <MenuItem value="NO">NO</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Etnia"
                                name="etnia"
                                fullWidth
                                select
                                error={Boolean(formik.touched.etnia && formik.errors.etnia)}
                                onChange={formik.handleChange}
                                value={formik.values.etnia}
                                helperText={formik.touched.etnia && formik.errors.etnia}
                            >
                                <MenuItem value="SI">SI</MenuItem>
                                <MenuItem value="NO">NO</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Fecha Inicio Contrato"
                                    slotProps={{ textField: { fullWidth: true } }}
                                    format='dd-MM-yyyy'
                                    //value={document.inicio_contrato ? document.inicio_contrato : ""}
                                    value={formik.values.inicio_contrato ? formik.values.inicio_contrato : ""}
                                    onChange={(newValue) => formik.setFieldValue('inicio_contrato', newValue, true)}
                                    renderInput={(params) => <TextField {...params} fullWidth
                                        error={Boolean(formik.touched.inicio_contrato && formik.errors.inicio_contrato)}
                                        helperText={formik.touched.inicio_contrato && formik.errors.inicio_contrato}
                                    />}
                                />
                            </LocalizationProvider>
                            <Typography variant="inherit" color="textSecondary">
                                {formik.touched.inicio_contrato && formik.errors.inicio_contrato}
                            </Typography>
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Anigüedad en la Empresa"
                                name="antiguedad_empresa"
                                fullWidth
                                error={Boolean(formik.touched.antiguedad_empresa && formik.errors.antiguedad_empresa)}
                                onChange={formik.handleChange}
                                value={formik.values.antiguedad_empresa}
                                helperText={formik.touched.antiguedad_empresa && formik.errors.antiguedad_empresa}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Fono de Contacto Emergencia"
                                name="fono_contacto_emergencia"
                                fullWidth
                                error={Boolean(formik.touched.fono_contacto_emergencia && formik.errors.fono_contacto_emergencia)}
                                onChange={formik.handleChange}
                                value={formik.values.fono_contacto_emergencia}
                                helperText={formik.touched.fono_contacto_emergencia && formik.errors.fono_contacto_emergencia}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Jornada"
                                name="jornada"
                                fullWidth
                                error={Boolean(formik.touched.jornada && formik.errors.jornada)}
                                onChange={formik.handleChange}
                                value={formik.values.jornada}
                                helperText={formik.touched.jornada && formik.errors.jornada}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="E-Mail"
                                name="mail"
                                fullWidth
                                error={Boolean(formik.touched.mail && formik.errors.mail)}
                                onChange={formik.handleChange}
                                value={formik.values.mail}
                                helperText={formik.touched.mail && formik.errors.mail}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Motivo de Desvinculación"
                                name="motivo_desvinculacion"
                                fullWidth
                                error={Boolean(formik.touched.motivo_desvinculacion && formik.errors.motivo_desvinculacion)}
                                onChange={formik.handleChange}
                                value={formik.values.motivo_desvinculacion}
                                helperText={formik.touched.motivo_desvinculacion && formik.errors.motivo_desvinculacion}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Nombre Conctacto de Emergencia"
                                name="nombre_contacto_emeregencia"
                                fullWidth
                                error={Boolean(formik.touched.nombre_contacto_emeregencia && formik.errors.nombre_contacto_emeregencia)}
                                onChange={formik.handleChange}
                                value={formik.values.nombre_contacto_emeregencia}
                                helperText={formik.touched.nombre_contacto_emeregencia && formik.errors.nombre_contacto_emeregencia}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Presenta Demanda"
                                name="presenta_demanda"
                                fullWidth
                                //select
                                error={Boolean(formik.touched.presenta_demanda && formik.errors.presenta_demanda)}
                                onChange={formik.handleChange}
                                value={formik.values.presenta_demanda}
                                helperText={formik.touched.presenta_demanda && formik.errors.presenta_demanda}
                            >
                                {/* <MenuItem value="SI">SI</MenuItem>
                                <MenuItem value="NO">NO</MenuItem> */}
                            </TextField>
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Sexo"
                                name="sexo"
                                fullWidth
                                select
                                error={Boolean(formik.touched.sexo && formik.errors.sexo)}
                                onChange={formik.handleChange}
                                value={formik.values.sexo}
                                helperText={formik.touched.sexo && formik.errors.sexo}
                            >
                                <MenuItem value="F">F</MenuItem>
                                <MenuItem value="M">M</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Sindicato"
                                name="sindicato"
                                fullWidth
                                error={Boolean(formik.touched.sindicato && formik.errors.sindicato)}
                                onChange={formik.handleChange}
                                value={formik.values.sindicato}
                                helperText={formik.touched.sindicato && formik.errors.sindicato}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Tipo de Contrato"
                                name="tipo_contrato"
                                fullWidth
                                error={Boolean(formik.touched.tipo_contrato && formik.errors.tipo_contrato)}
                                onChange={formik.handleChange}
                                value={formik.values.tipo_contrato}
                                helperText={formik.touched.tipo_contrato && formik.errors.tipo_contrato}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Consideraciones Importantes"
                                name="consideraciones_importantes"
                                fullWidth
                                error={Boolean(formik.touched.consideraciones_importantes && formik.errors.tipconsideraciones_importanteso_contrato)}
                                onChange={formik.handleChange}
                                value={formik.values.consideraciones_importantes}
                                helperText={formik.touched.consideraciones_importantes && formik.errors.consideraciones_importantes}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="AFP"
                                name="afp"
                                fullWidth
                                error={Boolean(formik.touched.afp && formik.errors.afp)}
                                onChange={formik.handleChange}
                                value={formik.values.afp}
                                helperText={formik.touched.afp && formik.errors.afp}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Salud"
                                name="salud"
                                fullWidth
                                error={Boolean(formik.touched.salud && formik.errors.salud)}
                                onChange={formik.handleChange}
                                value={formik.values.salud}
                                helperText={formik.touched.salud && formik.errors.salud}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="UF Salud"
                                name="uf_salud"
                                fullWidth
                                error={Boolean(formik.touched.uf_salud && formik.errors.uf_salud)}
                                onChange={formik.handleChange}
                                value={formik.values.uf_salud}
                                helperText={formik.touched.uf_salud && formik.errors.uf_salud}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Banco"
                                name="banco"
                                fullWidth
                                error={Boolean(formik.touched.banco && formik.errors.banco)}
                                onChange={formik.handleChange}
                                value={formik.values.banco}
                                helperText={formik.touched.banco && formik.errors.banco}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Tipo de Cuenta Bancaria"
                                name="tipo_cuenta_bancaria"
                                fullWidth
                                error={Boolean(formik.touched.tipo_cuenta_bancaria && formik.errors.tipo_cuenta_bancaria)}
                                onChange={formik.handleChange}
                                value={formik.values.tipo_cuenta_bancaria}
                                helperText={formik.touched.tipo_cuenta_bancaria && formik.errors.tipo_cuenta_bancaria}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Número de Cuenta Bancaria"
                                name="numero_cuenta"
                                fullWidth
                                error={Boolean(formik.touched.numero_cuenta && formik.errors.numero_cuenta)}
                                onChange={formik.handleChange}
                                value={formik.values.numero_cuenta}
                                helperText={formik.touched.numero_cuenta && formik.errors.numero_cuenta}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Nivel Educacional"
                                name="nivel_educacional"
                                fullWidth
                                error={Boolean(formik.touched.nivel_educacional && formik.errors.nivel_educacional)}
                                onChange={formik.handleChange}
                                value={formik.values.nivel_educacional}
                                helperText={formik.touched.nivel_educacional && formik.errors.nivel_educacional}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Titúlo"
                                name="titulo"
                                fullWidth
                                error={Boolean(formik.touched.titulo && formik.errors.titulo)}
                                onChange={formik.handleChange}
                                value={formik.values.titulo}
                                helperText={formik.touched.titulo && formik.errors.titulo}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Talla de Ropa"
                                name="talla_ropa"
                                fullWidth
                                error={Boolean(formik.touched.talla_ropa && formik.errors.talla_ropa)}
                                onChange={formik.handleChange}
                                value={formik.values.talla_ropa}
                                helperText={formik.touched.talla_ropa && formik.errors.talla_ropa}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Talla de Calzado"
                                name="talla_calzado"
                                fullWidth
                                error={Boolean(formik.touched.talla_calzado && formik.errors.talla_calzado)}
                                onChange={formik.handleChange}
                                value={formik.values.talla_calzado}
                                helperText={formik.touched.talla_calzado && formik.errors.talla_calzado}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Promedio Nota Evaluación de Desempeño"
                                name="promedio_nota_eval_desempeno"
                                fullWidth
                                error={Boolean(formik.touched.promedio_nota_eval_desempeno && formik.errors.promedio_nota_eval_desempeno)}
                                onChange={formik.handleChange}
                                value={formik.values.promedio_nota_eval_desempeno}
                                helperText={formik.touched.promedio_nota_eval_desempeno && formik.errors.promedio_nota_eval_desempeno}
                            />
                        </Grid>
                        <Grid item xl={12} sm={4}>
                            <TextField
                                label="Enfermedad Preexistente"
                                name="enfermedad_preexistente"
                                fullWidth
                                error={Boolean(formik.touched.enfermedad_preexistente && formik.errors.enfermedad_preexistente)}
                                onChange={formik.handleChange}
                                value={formik.values.enfermedad_preexistente}
                                helperText={formik.touched.enfermedad_preexistente && formik.errors.enfermedad_preexistente}
                            />
                        </Grid>
                        {usuario.perfil === "administrador" ? (
                            <>
                                <Grid item xl={12} sm={4}>
                                    <TextField
                                        label="Contrato"
                                        name="id_contrato"
                                        fullWidth
                                        select
                                        error={Boolean(formik.touched.id_contrato && formik.errors.id_contrato)}
                                        onChange={formik.handleChange}
                                        value={formik.values.id_contrato}
                                        helperText={formik.touched.id_contrato && formik.errors.id_contrato}
                                    >
                                        {contratos.map((c, i) => (
                                            <MenuItem key={i} value={c.id}>{c.abreviado}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xl={12} sm={4}>
                                    <TextField
                                        label="Vigente"
                                        name="vigente"
                                        fullWidth
                                        select
                                        error={Boolean(formik.touched.vigente && formik.errors.vigente)}
                                        onChange={formik.handleChange}
                                        value={formik.values.vigente}
                                        helperText={formik.touched.vigente && formik.errors.vigente}
                                    >
                                        <MenuItem key="SI" value="SI">SI</MenuItem>
                                        <MenuItem key="NO" value="NO">NO</MenuItem>
                                    </TextField>
                                </Grid>
                            </>
                        ) : ""}
                    </Grid>
                    <Divider></Divider>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    alignContent: "end",
                    justifyContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    <Button variant='contained' sx={{backgroundColor: 'black'}} onClick={formik.handleSubmit}>{formik.values.id ? "Actualizar" : "Agregar"}</Button>
                </Box>
            </Container>
        </div>
    )
}

export default function AddPersonaSnack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <AddPersona />
        </SnackbarProvider>
    )
}